
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 62.5%;
  background: #fafafa;
}
